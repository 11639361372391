<template>
    <div>
        <v-container
            class="municipality-background"
            max-width="1080"
            fluid
            v-if="!this.$route.params.courseId"
        >
            <v-row class="padding">
                <v-col
                    key="left"
                    cols="auto"
                    sm="12"
                    justify="center"
                    text-align="center"
                >
                    <h1>
                        <span class="heading1"
                            >{{ this.municipality.homeHeaderFirstPart }} </span
                        ><span class="heading2">
                            {{ this.municipality.homeHeaderEndPart }}</span
                        >
                    </h1>

                    <div class="subheading-wrapper">
                        <p
                            class="text"
                            v-html="this.municipality.homeSubHeader"
                        ></p>
                    </div>
                </v-col>
            </v-row>
            <v-row class="padding">
                <v-col
                    v-for="course in this.municipality.courses"
                    :key="course.id"
                    cols="auto"
                    xs="12"
                    sm="6"
                    md="4"
                    lg="3"
                    justify="center"
                    text-align="center"
                >
                    <CourseCard
                        :title="course.cardHeading"
                        :subtitle="course.cardText"
                        :image="course.cardImage"
                        :describtion="course.cardDescribtion"
                        :courseId="course.id"
                    />
                </v-col>
            </v-row>
        </v-container>
        <router-view></router-view>
        <authorization-modal/>
    </div>

</template>
<script>
import { getMunicipality } from '../plugins/api_requests';
import CourseCard from '../components/CourseCard.vue';
import AuthorizationModal from '../components/AuthorizationModal.vue';

export default {
    name: 'MunicipalityHome',

    components: {
        CourseCard,
        AuthorizationModal,
    },

    data() {
        return {
            municipality: [],
            dialog: false,
            loading: true,
            municipalityErrorMessage: [],
        };
    },

    beforeMount() {
        this.getMunicipalityFromAPI(this.$route.params.municipalityId);

        // Set cookie
        this.$cookies.set(
            'municipality',
            this.$route.params.municipalityId,
            '6y',
        );
    },

    mounted() {},

    methods: {
        async getMunicipalityFromAPI(id) {
            await getMunicipality(id, {
                params: {
                    user_uuid: this.$cookies.get('uuid'),
                    municipality_id: this.$cookies.get('municipality'),
                },
            })
                .then((result) => {
                    this.municipality = result.data;
                    this.loading = false;
                })
                .catch(() => {
                    this.municipalityErrorMessage = 'Kunne ikke hente kommune.';
                    window.location = `${process.env.VUE_APP_APP_URL}/0`;
                });
        },
    },
};
</script>
<style lang='scss' scoped>
.heading1 {
    color: var(--v-municipality_header_1-base);
    font-size: 40px;
    font-weight: 600;
}
.heading2 {
    color: var(--v-municipality_header_2-base);
    font-size: 40px;
    font-weight: 600;
}
.heading-wrapper {
    display: flex;
    text-align: left;
    justify-content: left;
}
.subheading-wrapper {
    justify-content: center;
    margin-top: 15px;
    width: 80%;
}
.text {
    font-size: 18px;
    text-align: left;
}
.padding {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 2%;
}

.municipality-background {
    background-color: var(--v-municipality_background-base);
    height: 100%;
}
</style>
