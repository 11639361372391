<template>
    <v-card
        class="rounded-xl card-size"
        flat
        @click="goToCourse(courseId)"
        elevation="4"
    >
        <v-img :src="this.imageUrl" height="175px"></v-img>
        <div class="card-padding">
            <v-card-title class="title text-color">
                {{ this.title }}
            </v-card-title>

            <v-card-subtitle class="subtitle mb-5 text-color">
                {{ this.subtitle }}
            </v-card-subtitle>

            <v-card-actions>
                <v-btn
                    class="action-button elevation-0"
                    content-class=""
                    rounded
                    ripple
                    @click="goToCourse(courseId)"
                    >{{ $store.state.texts.course_card_button }}</v-btn
                >
                <v-spacer></v-spacer>

                <!-- <v-btn icon @click="show = !show">
                    <v-icon>{{
                        show ? 'mdi-chevron-up' : 'mdi-chevron-down'
                    }}</v-icon>
                </v-btn> -->
            </v-card-actions>

            <!-- <v-expand-transition>
                <div v-show="show">
                    <v-divider></v-divider>

                    <v-card-text class="subtitle">
                        {{ this.describtion }}
                    </v-card-text>
                </div>
            </v-expand-transition> -->
        </div>
    </v-card>
</template>

<script>
export default {
    name: 'CourseCard',

    props: ['title', 'subtitle', 'image', 'describtion', 'courseId'],

    mounted() {},

    data() {
        return {
            municipality: [],
            show: false,
            municipalityErrorMessage: [],
        };
    },

    computed: {
        imageUrl: function constructImageUrl() {
            return `${process.env.VUE_APP_STORAGE_URL}${this.image}`;
        },
    },

    methods: {
        goToCourse(courseId) {
            this.$router.replace({
                name: 'chapter',
                params: {
                    id: courseId,
                    courseId: this.courseId,
                    chapterId: 0,
                },
            });
        },
    },
};
</script>

<style scoped lang='scss'>
.card-size {
    max-width: 412px;
    background-color: var(--v-course_card_background-base);
    height: 430px;
}
.action-button {
    font-family: "Circe-rounded", sans-serif;
    font-weight: 600;
    text-transform: none;
    background-color: var(--v-button-base) !important;
    padding: 20px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: -20px !important;
    color: var(--v-button_text-base);
}

.v-card__actions {
    padding-bottom: 0px;
    margin-top:00px;
}

.title {
    font-weight: 500;
    font-size: 18px;
    font-family: "Circe-rounded", sans-serif;
    word-break: break-word;
    height: 96px !important;
}

.text-color {
    color: var(--v-course_card_text-base);
}

.subtitle {
    font-weight: 400;
    font-size: 16px;
    font-family: "Circe-rounded", sans-serif;
    height: 80px !important;
}

.card-padding {
    padding-left: 20px;
    padding-bottom: 0px;
}
</style>
